.Dashbord-header{
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 5px;
}
.Dashbord-header h5{
    color: rgb(255, 255, 255);
    background-color: rgb(150, 150, 150);
    padding: 5px;   
    font-weight: 600;
    cursor: pointer;
}
.Posts-Dash{
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: auto;
    margin-top: 15px;
}
.Posts-Dash img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
}
.Post-img-Dash{
    position: relative;
}
.Post-img-Dash h2{
    position: absolute;
    bottom: 0px;
    font-size: 16px;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 2px;
    cursor: pointer;
    background-color: white;
}