.Publiciter{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 55px;
}
.Publiciter-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width:100%;
}
.Publiciter-container-header{
    width: 94%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Publications-posts{
    width: 100%;
    padding: 0px 0 0 5px;
    display: flex;
    align-items: center;
    overflow-x:scroll;
    flex-wrap: nowrap; 

}
.Publications-post{
    flex: 0 0 auto; /* يمنع العناصر من التوسع خارج العرض المحدد لها */
    height: 250px;
    display: flex;
}
.Publications-post-container{
    background: linear-gradient(to top,rgba(0, 0, 0, 0.616),rgba(0, 0, 0, 0.041));
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    margin: 5px 5px 10px 5px;
    width: 300px;
    position: relative;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.Publications-post-price{
    width: fit-content;
    padding: 2px 5px;
    border-radius: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.Publications-post-price h3{
    margin: 5px;
    background-color: #398169;
    font-size: 15px;
    color: white;
    padding: 0 5px;
    border-radius: 2px;
    margin-bottom: 0;
    z-index: 1;
}
.Publications-post-price h3 span{
    color: white;
}
.Publications-post-price h2{
    font-size: 12px;
    background-color: aliceblue;
    width: fit-content;
    padding: 0 5px;
    border-radius: 2px;
    margin: 0;
    z-index: 1;
}
.Publications-post-info{
    display: flex;
    align-items: end;
    height:90%;
}
.Publications-post-info-text{
    display: flex;
    height: 100%;
    flex-direction: column;
}
.Publications-post-info-text h3{
    color: white;
    margin: 5px;
    font-family: sans-serif;
    margin-top: 15px;
    font-size: 18px;
    z-index: 1;
}
.Publications-post-info-text p{
    margin: 5px;
    margin-top: auto;
    font-family:sans-serif;
    font-size: 14px;
    color: white;
    margin-bottom: 0;
    z-index: 1;
}
.Publications-post-info-btn{
    background-color: yellow;
    padding: 5px 8px;
    border-radius: 12px;
    font-weight: 600;
    border: none;
    margin: 5px;
    margin-right: auto;
    height: 30px;
    z-index: 1;
}
.Post-Comp-container-img{
    max-height: 400px;
    min-height: 200px;
}
.Publications-post-container img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;

}
.Publications-post-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top,rgba(0, 0, 0, 0.315),rgba(0, 0, 0, 0)); /* لون أحمر مع شفافية 50% */
    border-radius: 8px;
}