.ProfileUser-container{
    padding: 0 0 15px 0;
    direction: rtl;
    width: 60%;
    margin: auto;
}
@media (max-width: 720px){
    .ProfileUser-container{
        width: 100%;
    }
}
.ProfileUser-container-img{
    background: linear-gradient(to top,rgba(0, 0, 0, 0.432),rgba(0, 0, 0, 0)),url(https://media.istockphoto.com/id/532343936/vector/vector-background-with-tropical-leaves.jpg?s=170667a&w=0&k=20&c=NSNVEwNSVQlDK1ziNxdtdqS-4lb9eRY58T8T8uvEOl4=);
    display: flex;
    background-position: center;
    background-size: cover;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.ProfileUser-container-img-arrow{
    width: 100%;
}
.ProfileUser-container-img-arrow h2{
    color: white;
    font-size: 30px;
}
.ProfileUser-container-img-img{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    flex-direction: column;
}
.ProfileUser-container-img-img img{
    height: 100px;
    width: 100px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    animation: imgProfileUser 1s infinite;
}
@keyframes imgProfileUser{
    0%{
        background-color: white;
    }
    50%{
        background-color: rgb(209, 209, 209);
    }
    100%{
        background-color: white;
    }
}
.ProfileUser-container-img-img h2{
    color: black;
    position:relative;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    left: 30px;
    bottom: 30px;
    cursor: pointer;
}
.ProfileUser-container-img-name{
    margin-bottom: 15px;
}
.ProfileUser-container-img-name h3{
    margin: 0;
    color: white;
    text-align: center;
}
.ProfileUser-container-img-name h4{
    color: white;
    font-size: 14px;
    width: 100%;
    text-align: center;
}
.ProfileUser-container-data-user{
    border-bottom: 1px solid rgb(197, 197, 197);
    display: flex;
    height: 60px;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ProfileUser-container-data-user h2{
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
.ProfileUser-container-data-user h2 span{
    color: rgb(158, 158, 158);
    font-size: 16px;
}
.ProfileUser-container-bio{
    width: 96%;
    margin: auto;
}
.ProfileUser-container-bio h2{
    width: fit-content;
    text-decoration: underline;
    font-size: 20px;
    margin-top: 5px;
}
.ProfileUser-container-bio p{
    color: rgb(90, 90, 90);
}
.ProfileUser-container-sociale-media{
    width: 96%;
    margin: auto;
}
.ProfileUser-container-sociale-media h2{
    font-size: 20px;
    text-decoration: underline;
}
.ProfileUser-container-sociale-media h2{
    width: 100%;
    display: flex;
    text-decoration: none;
    color: rgb(46, 46, 46);
    gap: 10px;
    background-color: rgb(231, 231, 231);
}
.ProfileUser-container-sociale-media h2 span{
    font-size: 20px;
    color: rgb(65, 65, 65);
    width: 90%;
}
.ProfileUser-container-sociale-media h3{
    font-size: 20px;
    text-decoration: underline;
    margin-bottom: 15px;
}
.ProfileUser-container-trend-post{
    display: flex;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}
.ProfileUser-container-trend-post img{
    height: 149px;
    width: 100px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.281) 0px 5px 15px;
    object-position: center;
    cursor: pointer;
}
.ProfileUser-container-trend-post h2{
    width: 96%;
    text-decoration: underline;
    font-size: 20px;
}
.ProfileUser-container-trend-post-images{
    display: flex;
    gap: 10px;
    margin: 10px 0 10px 0; 
}
.ProfileUser-container-trend-post-images img{
    border: 1px solid rgb(124, 124, 124);
    background-color: rgb(240, 240, 240);
    background-position: center;
    background-size: cover;
}
.ProfileUser-container-trend-post p{
    width: 96%;
    color: rgb(90, 90, 90);
}