.Dash-users{
    width: 96%;
    margin: auto;
}
.Dash-user{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
    height: 45px;
    border: 1px solid rgb(185, 184, 184);
}
.Dash-users-component{
    border: 1px solid rgb(185, 185, 185);
    margin-top: 15px;
    border-radius: 5px;
    box-shadow: rgba(158, 158, 158, 0.35) 0px 5px 15px;
}
.Dash-user-img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Dash-user-img img{
    height: 35px;
    width: 35px;
    background-color: aqua;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
}
.Dash-user #h6-header{
    color: rgb(90, 90, 90);
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgb(187, 187, 187);
}
.Dash-users-component-header{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.Dash-users-component-header #h6-header{
    height: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    text-align: center;
    color: rgb(90, 90, 90);
}
.Dash-user-views{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    height: 45px;
    border-left: 1px solid rgb(126, 126, 126);
}
.Dash-user-views button{
    border: none;
    color: white;
    padding:2px 5px ;
    border-radius: 8px;
}