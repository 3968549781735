.emailVerify{
    height: 100vh;
    background-color: rgb(243, 243, 243);
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to top,rgba(0, 170, 91, 0.411),rgb(255, 255, 255)),url(https://t3.ftcdn.net/jpg/03/21/19/20/360_F_321192044_GX868sdU1zhJgduLFzKkFsUQRHyiFmVL.jpg);
    background-position: center;
    background-size: cover;
}
.emailVerify-container{
    background-color: rgb(255, 255, 255);
    width: 80%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: Arial, sans-serif;
    transition: all 0.3s ease;
    border: 1px solid rgb(190, 190, 190);
    z-index: 1;
    overflow: hidden;
    padding: 50px;
}
.emailVerify-container h1{
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
}   
.emailVerify-container p{
    color: rgb(99, 99, 99);
    font-weight: 700;
    margin-bottom: 0;
}
.emailVerify-container button{
    margin-top: 20px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
    transition: all 0.3s ease;
}
@media (max-width : 650px) {
    .emailVerify-container{
        width: 90%;
    }
}