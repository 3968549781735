.UpdatePass{
    background: url(https://img.freepik.com/free-vector/watercolor-nature-background-with-leaves_52683-59449.jpg);
    height: 100vh;
    background-position:  center;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.UpdatePass-container{
    padding-top: 55px;
    backdrop-filter: blur(20px);
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.UpdatePass-container h2{
    font-weight: 700;
    color: rgb(0, 104, 9);
}
.UpdatePass-container p{
    font-weight: 600;
    color: rgb(109, 109, 109);
    width: 80%;
    text-align: center;
}
.UpdatePass-container form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    gap: 10px;
    direction: rtl;
}
.UpdatePass-container form label{
    font-weight: 700;
    width: 100%;
    text-decoration: underline;
}
.UpdatePass-container form input{
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid green;
}
.UpdatePass-container form button{
    width: 100%;
    background-color: rgb(0, 104, 0);
    color: white;
    border-radius: 5px;
    border: none;
    height: 45px;
}
@media (max-width: 720px){
    .UpdatePass-container{
        width: 100%;
    }
}