@import url('https://fonts.googleapis.com/css2?family=Fustat:wght@200..800&display=swap');

.CreatePost{
    background-color: #ffffff;
    direction: rtl;
    font-family: "Fustat", sans-serif;
    font-optical-sizing: auto;
    width: 60%;
    margin: auto;
}
@media (max-width: 720px){
    .CreatePost{
        width: 100%;
    }
}
.CreatePost-header{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;
    direction: rtl;
}
.CreatePost-header h2{
    margin: 0;
}
.CreatePost-header img{
    height: 30px;
    cursor: pointer;
}
.CreatePost-header h2{
    color: #8d8d8d;
    cursor: pointer;
}
.CreatePost-header h3{
    color: #8d8d8d;
    font-size: 18px;
    margin: 0;
}
.CreatePost-header h4{
    color: #8d8d8d;
    font-size: 18px;
    margin: 0;
}
.CreatePost-Img{
    width: 90%;
    background-color: #398169;
    margin: auto;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
}
.CreatePost-Img #imageFile{
    border-radius: 5px;
    opacity: 0.1;
    object-fit: cover;
    height: 150px;
    object-position: center;
}
.CreatePost-Img #file{
    border-radius: 5px;
    opacity: 1;
    object-fit: cover;
    width: 100%;
    object-position: center;
}
.CreatePost-Img h2{
    position: absolute;

}
.CreatePost-Img:hover{
    background-color: #488a74;
}
.CreatePost-Img h2{
    font-size: 40px;
    color: white;
}
.CreatePost-info-post{
    width: 86%;
    margin: auto;
    margin-top: 8px;
}
.CreatePost-info-post h5{
    color: #666666;
    font-size: 18px;
    text-decoration: underline;
}
.CreatePost-info-post input{
    width: 100%;
    background-color: #e9e9e9;
    height: 45px;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 0 5px;
    margin: 5px 0;
}
.CreatePost textarea {
    height: auto;
    overflow-y: hidden;
    width: 100%;
    background-color: #e9e9e9;
    height: 100px;
    border: none;
    border-radius: 5px;
    padding: 5px;
    resize: none;
}
.form-select{
    background-color: #e9e9e9;
    color: #8d8d8d;
    font-size: 17px;
}
.form-select option{
    color: black;
    font-size: 17px;
}
.CreatePost-button{
    margin: 20px 0 20px 0; 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.CreatePost-button button{
    background-color: #398169;
}
.CreatePost-header .notification{
    position: relative;
}
.CreatePost-header .notification span{
    position: absolute;
    font-size: 14px;
    background-color: red;
    padding: 2px;
    color: white;
    border-radius: 25%;
}
.verfication-account{
    background-color: yellow;
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 15px;
}
.verfication-account h6{
    margin: 0;
    color: white;
    background-color: #116EF6;
    padding: 5px 10px;
    border-radius: 12px;
}

#bottom-header{
    background: linear-gradient(to top,rgba(0, 0, 0, 0.075),rgb(241, 241, 241));
    width: 96%;
    font-size: 15px;
    margin: auto;
    border-radius: 12px;
    margin-top: 16px;
    color: #616161;
    padding: 10px;
    border: 1px solid rgb(131, 131, 131);
    cursor: pointer;
}