.sendNotif{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.sendNotif form{
    width: 50%;
}
.sendNotif form h6{
    font-weight: 700;
}
.sendNotif form input{
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: none;
    padding:  0 0 0 2px;
    background-color: rgb(235, 235, 235);

}
.sendNotif form textarea{
    width: 100%;
    height: 100px;
    background-color: rgb(235, 235, 235);
    border: none;
    border-radius: 5px;

}